/* NOTE: make sure links have https:// in front or they will not work */

export const leadership = [
  {
    name: "Raghav Punnam",
    img: null,
    role: "President",
    year: "",
    committee: null,
    email: "raghavpu@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/raghav-punnam/",
    resume: null,
    website: null,
    blurb:
      "Hey, I'm Raghav! I love playing basketball, working out, and doing spontaneous things :) ",
  },
  {
    name: "Alyssa Smith",
    img: null,
    role: "Vice President",
    year: "Junior",
    committee: null,
    email: "alyssa.427@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/alyssa-smith-physics/",
    resume: null,
    website: null,
    blurb:
      "Hi!! I'm a junior intending on studying CS/DS. I like word games, drinking ungodly amounts of caffeine, and listening to sad boi indie rock :'-)",
  },
  {
    name: "Anna Chung",
    img: null,
    role: "External Admin Director",
    email: "",
    year: "",
    committee: null,
    project: null,
    tree: "",
    leaf: "",
    linkedin: "https://www.linkedin.com/in/annajchung/",
    blurb:
      "heyo :D! my name is anna and i'm a junior studying computer science & data science! i love rats 🐀, space & physics🌌, machine learning 👩🏻‍💻, music, and art (the picture is from a murakami exhibit)!",
  },
  {
    name: "Euhan Kim",
    img: null,
    role: "Internal Admin Director",
    email: "euhan123@berkeley.edu",
    year: "Junior",
    committee: null,
    project: null,
    tree: "Cynthia Lan",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/euhan-kim/",
    resume: null,
    website: null,
    blurb: "Hi, I'm Euhan! Play league with me :)) and gimme free food plz",
  },
  {
    name: "Jaewon Lee",
    img: null,
    role: "Tech Director",
    year: "",
    committee: null,
    email: "jaewon.lee@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/thejaewonlee/",
    resume: null,
    website: null,
    blurb:
      "hey everone, im jaewon! when im not coding away or working on psets, i love going on hikes, playing tennis, or spontaneously going on trips out to sf :D",
  },
  {
    name: "Alena Chao",
    img: null,
    role: "Tech Director",
    year: "",
    committee: null,
    email: "alenachao@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/alenachao",
    resume: null,
    website: null,
    blurb:
      "hallo my name is alena and im a sophomore in eecs!! talk to me abt being a god in brawl stars, being trash at badminton, enhypens latest album, or literally anything else :D looking forward to meeting u!!",
  },
  {
    name: "Minjune Kim",
    img: null,
    role: "Education Director",
    year: "",
    email: "",
    linkedin: null,
    tree: null,
    leaf: null,
    committee: null,
    project: null,
    resume: null,
    website: null,
    blurb: "My best friend is Victor Zhou.",
  },
  {
    name: "Ethan Herrera",
    img: null,
    role: "Education Director",
    year: "",
    committee: null,
    email: "ethanherrera@berkeley.edu",
    repeat: "2",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/ethanherrera/",
    resume: null,
    website: null,
    blurb:
      "Hi, I'm Ethan! I am a sophomore majoring in EECS. I love rock music, weightlifting, board games, and everything in between. Fun fact, I have been to over 50 live concerts in the Berkeley area. If you want to do absolutely anything and want a +1, let me know.",
  },
  {
    name: "Andrew Choy",
    img: null,
    role: "Social Director",
    email: "",
    repeat: "2",
    year: "Sophomore",
    committee: null,
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb: "I can squat 600, bicep curl 75. ",
  },
  {
    name: "Dhruv Ganesh",
    img: null,
    role: "Social Director",
    email: "dganesh@berkeley.edu",
    year: "Freshman",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    linkedin: null,
    resume: null,
    website: null,
    blurb: "If u mention Taco Bell plots I’m already down",
  },
  {
    name: "Esther Shen",
    img: null,
    role: "Media Director",
    year: "",
    committee: null,
    email: "esther.shen@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb:
      "hello!! i’m a junior studying CS and Linguistics. i like languages, nails, hair, and fueling my concert-going addiction ⊂( ´ ▽ ` )⊃",
  },
  {
    name: "Colleen Pangilinan",
    img: null,
    role: "Media Director",
    year: "junior",
    committee: null,
    email: "colleenpangilinan@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/colleen-pangilinan-292241247",
    resume: null,
    website: null,
    blurb: "",
  },
  {
    name: "Alicia Gullon",
    img: null,
    role: "Member Relations Director",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/alicia-gullon/",
    resume: null,
    website: null,
    blurb: "Hi, I'm Alicia! I like judo, art, music, and yummy desserts :D.",
  },
  {
    name: "Kate Trevor",
    img: null,
    role: "Member Relations Director",
    email: "",
    year: "Sophomore",
    committee: null,
    project: null,
    tree: "Jada",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb: "I won gold at junior olympics soccer. ",
  },
  {
    name: "Timothy Park",
    img: null,
    role: "Finance Director",
    email: "",
    year: "Sophomore",
    committee: null,
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb: "I like baseball.",
  },
  {
    name: "Yunze Du",
    img: null,
    role: "Web Director",
    year: "",
    committee: null,
    email: "yzdu@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/yunzedu/",
    resume: null,
    website: null,
    blurb:
      "let's chat about programming, economics, data, but also specialty coffee, guitar, and which aquarium is the best on earth!",
  },
  {
    name: "Natalie Wei",
    img: null,
    role: "Sourcing Director",
    year: "",
    committee: null,
    email: "nataliewei@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/natalie-wei/",
    resume: null,
    website: null,
    blurb:
      "hi im natalie, a sophomore studying cs and linguistics! i like reading and doing crosswords",
  },
  {
    name: "Rohan Gulati",
    img: null,
    role: "Sourcing Director",
    email: "",
    year: "Sophomore",
    committee: null,
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb: "My favorite restaraunt is Tony's Style",
  },
];

export const projectLeaders = [
  {
    name: "Angie Baik",
    img: null,
    role: "Project Leader",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "http://linkedin.com/in/angie-baik",
    resume: null,
    website: null,
    blurb:
      "Hi! I’m Angie a freshman studying Applied Math and CS. I love baking, puzzles, and watching movies/shows. ",
  },
  {
    name: "Kelly Yeh",
    img: null,
    role: "Project Leader",
    year: "",
    committee: null,
    email: "kellyyyeh@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/kelly-yeh-b08217232/",
    resume: null,
    website: null,
    blurb: "hii! i love going on walks, good food, and you :p",
  },
  {
    name: "Heidy Hernandez",
    img: null,
    role: "Project Leader",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/heidyhernandezjuan/",
    resume: null,
    website: null,
    blurb:
      "hello :D i'm a freshman studying eecs. i love everything kpop, sitcoms (modern family, the derry girls, etc.) and just chilling",
  },
  {
    name: "Navi Emiliano",
    img: null,
    role: "Project Leader",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/navi-emiliano-785603201/",
    resume: null,
    website: null,
    blurb:
      "hihiii, i like going to eat, playing volleyball, and i’m currently into stardew valley :DD",
  },
  {
    name: "Miller Liu",
    img: null,
    role: "Project Leader",
    year: "Freshman",
    committee: null,
    email: "",
    project: null,
    tree: "David",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb: "I can run a 4 minute mile. ",
  },
  {
    name: "Samhita Ghosh",
    img: null,
    role: "Project Leader",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/samhita-ghosh/",
    resume: null,
    website: null,
    blurb:
      "hi im samhita! i love eating (lets cook tgt :D), karaoke and binging romcom kdramas <3",
  },
  {
    name: "Xina Wang",
    img: null,
    role: "Project Leader",
    year: "Freshman",
    committee: null,
    email: "xina.wang@berkeley.edu",
    project: null,
    tree: "Stephen",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/xina-wang/",
    resume: null,
    website: null,
    blurb:
      "Heyo!! I'm Xina and I'm a freshman studying chemical biology and intending DS. Road work ahead? _   _ _ _ _   _ _ _ _   _ _   _ _ _ _.",
  },
  {
    name: "Aditi Mundra",
    img: null,
    role: "Project Leader",
    email: "",
    year: "",
    committee: null,
    project: null,
    tree: "",
    leaf: "",
    linkedin: "https://www.linkedin.com/in/aditi-mundra/",
    blurb:
      "helloo! i'm a freshman studying EECS and i love trying new foods and sleeping.",
  },
  // {
  //   name: "Riley Reichel",
  //   img: null,
  //   role: "Project Leader",
  //   email: "",
  //   year: "Sophomore",
  //   committee: null,
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "",
  //   resume: null,
  //   website: null,
  //   blurb: "I was puking while someone else was puking on someone's phone. ",
  // },
  {
    name: "April Zhang",
    img: null,
    role: "Project Leader",
    year: "Junior",
    committee: null,
    email: "april.zhang@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin/in/aprilczhang/",
    resume: null,
    website: null,
    blurb:
      "Hi, I’m April! I’m a junior studying EECS and minoring in Philosophy. Talk to me about cooking, music recs, kickboxing, my two thriving avocado plants, or life in general! (☞ ͡° ͜ʖ ͡°)☞",
  },
  {
    name: "Grace Juan",
    img: null,
    role: "Project Leader",
    year: "Sophomore",
    email: "grace.wj@berkeley.edu",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    linkedin: null,
    resume: null,
    website: null,
    repeat: "2",
    blurb:
      "Hey, I'm Grace! I'm a sophomore intending to major in computer science. I like tennis, piano, art, games, and music.",
  },
  {
    name: "Christina Yue",
    img: null,
    role: "Project Leader",
    year: "Sophmore",
    email: "",
    linkedin: "",
    tree: null,
    leaf: null,
    committee: null,
    project: null,
    resume: null,
    website: "",
    blurb: "I can bench 225.",
  },
  {
    name: "Hailey Pham",
    img: null,
    role: "Project Leader",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/haileyphham",
    resume: null,
    website: null,
    blurb: "i love gym, nails, and watermelon sour patches d:",
  },
  {
    name: "Shing Gao",
    img: null,
    role: "Project Leader",
    email: "",
    year: "",
    committee: null,
    project: null,
    tree: "",
    leaf: "",
    linkedin: "https://www.linkedin.com/in/shinggao/",
    blurb:
      "hi my name is shing and i am a bioe + eecs major. i love nature and my cat and i have lots of photographs of both. i have a complex relationship with pop music. ask me about my notes app ;p",
  },
  {
    name: "Alana Yang",
    img: null,
    role: "Project Leader",
    email: "",
    year: "Junior",
    committee: null,
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb: "I watched someone puke on someone's phone. ",
  },
];

const a = [
  {
    name: "Avo",
    img: null,
    role: "Mascot",
    year: "??",
    email: "info@codeology.club",
    linkedin: "https://www.linkedin.com/company/berkeley-codeology/mycompany/",
    tree: "Persea americana",
    blurb:
      "I like guac, Codeology, and being cute! I can be seen tabling with Codeology members — feel free to come by, say hi, and give me a pat <3",
  },
  {
    name: "Alex Chen",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "alexchen4@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/alexxcchen/",
    resume: null,
    website: null,
    blurb: "i like taylor swift",
  },
  // {
  //   name: "Angel Mau",
  //   img: null,
  //   role: "Active Member",
  //   year: "Junior",
  //   email: "angelmau@berkeley.edu",
  //   linkedin: "www.linkedin.com/in/angelmau",
  //   tree: null,
  //   leaf: null,
  //   committee: null,
  //   project: null,
  //   resume: null,
  //   website: null,
  //   blurb: "I like lifting and console gaming!",
  // },
  {
    name: "Allan Chen",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "allanchen511@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "linkedin.com/in/allanchen51",
    resume: null,
    website: null,
    blurb:
      "i am currently a sophomore studying CS and DS! i love beef noodle soup and am quite spontaneous, so if you love to gig around at 2 am, i'd be down!",
  },
  // {
  //   name: "Annie Xu",
  //   img: null,
  //   role: "Active Member",
  //   email: "annie.xu2025@berkeley.edu",
  //   year: "Freshman",
  //   committee: null,
  //   project: null,
  //   tree: null,
  //   leaf: null,
  //   linkedin: null,
  //   resume: null,
  //   website: null,
  //   blurb: "hi! i like volleyball, napping, sushi, and going on adventures",
  // },
  // {
  //   name: "John Glen Siy",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "johnglen_siy@berkeley.edu",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/john-glen-siy-055805228/",
  //   resume: null,
  //   website: null,
  //   blurb: "I can't bench 225.",
  // },
  {
    name: "Justin Xu",
    img: null,
    role: "Active Member",
    year: "Sophmore",
    email: "justinxu@berkeley.edu",
    committee: null,
    project: null,
    tree: null,
    leaf: null,
    linkedin: "https://www.linkedin.com/in/justin-xu-556b6521b/",
    resume: null,
    website: null,
    blurb:
      "hi! i love meeting people, trying new things, fruit, sports and not writing bios.",
  },
  {
    name: "Liz Weaver",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "elizabeth.weaver@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "www.linkedin.com/in/e-j-weaver",
    resume: null,
    website: null,
    blurb:
      "hii!! i'm liz :) sometimes i dance and bake. i also love picnics and good eats",
  },
  {
    name: "Danny Tran",
    img: null,
    role: "Active Member",
    year: "Senior",
    committee: null,
    email: "dannyltran@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "www.linkedin.com/in/danny-tran-2764101a5",
    resume: null,
    website: null,
    blurb:
      "Hi! I am Danny, a second year CS student from the San Fernando Valley. I really enjoy naturey things like camping and taking walks!",
  },
  {
    name: "Vivek Putta",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb:
      "Hi, my name is Vivek! I love to play Smash, basketball, or just chill out with some movies and pizza.",
  },
  {
    name: "Tommy Hang",
    img: null,
    role: "Active Member",
    year: "Sophmore",
    email: "tommyhang@berkeley.edu",
    linkedin: "https://www.linkedin.com/in/tommy-hang-69a433212/",
    tree: null,
    leaf: null,
    committee: null,
    project: null,
    resume: null,
    website: "https://www.instagram.com/imatomster/",
    blurb:
      "THATS CRAZY! If you ever want to eat at Crossroads, I'll always eat with you. I love music, fencing, basketball, other stuff, and you <3",
  },
  //{
  //    name: 'Pradyun Kumar',
  //    img: null,
  //    role: 'Active Member',
  //    email: 'pradyunkumar@berkeley.edu',
  //    year: 'Junior',
  //    committee: null,
  //    project: null,
  //    tree: null,
  //    leaf: null,
  //    linkedin: null,
  //    resume: null,
  //    website: null,
  //    blurb: "hi I like guacomole and anime and Kpop and listening to piano while I study",
  //},
  //{
  //    name: 'Ashley Chu',
  //    img: null,
  //    role: 'Active Member',
  //    email: 'ashchu@berkeley.edu',
  //    repeat: '2',
  //    year: 'Junior',
  //    committee: 'Website',
  //    project: null,
  //    tree: 'Ada',
  //    leaf: 'Maya',
  //    linkedin: 'https://linkedin.com/in/ashley-chu',
  //    resume: null,
  //    website: 'https://ashchu.github.io/',
  //    blurb: "hi! :^) i like egg tarts and movies. as you can see the name ashley is >>>",
  //},
  //{
  //    name: 'Charles Ming',
  //    img: null,
  //    role: 'Active Member',
  //    year: 'Sophomore',
  //    email: 'charlesming@berkeley.edu',
  //    linkedin: "https://www.linkedin.com/in/charlesming/",
  //    tree: null,
  //    leaf: null,
  //    committee: null,
  //    project: null,
  //    resume: null,
  //    website: null,
  //    blurb: "i like playing chess + poker, making no progress at rsf, reading, and trying weird foods",
  //},
  // {
  //   name: "Chetan Khanna",
  //   img: null,
  //   role: "Active Member",
  //   email: "chetan.khanna@berkeley.edu",
  //   year: "Junior",
  //   committee: null,
  //   project: null,
  //   tree: null,
  //   leaf: null,
  //   linkedin: null,
  //   resume: null,
  //   website: null,
  //   blurb: null,
  // },
  // {
  //   name: "Cynthia Lan",
  //   img: null,
  //   role: "Active Member",
  //   year: "Sophomore",
  //   email: "cynthialan@berkeley.edu",
  //   committee: "Social Committee",
  //   project: "Sea Warden, Build-A-Bear",
  //   tree: "Kristie",
  //   leaf: "Euhan",
  //   linkedin: null,
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Hi I’m Cynthia! I like going to the beach, spontaneous trips and card games!",
  // },
  {
    name: "Danica Tang",
    img: null,
    role: "Active Member",
    email: "danicat@berkeley.edu",
    year: "",
    committee: null,
    project: null,
    tree: "Maya Zheng",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/danica-t",
    resume: null,
    website: null,
    blurb:
      "hi! I’m Danica, a junior studying CS <3 I love taking pictures, crafting (recently in my slime phase), playing instruments, cooking, painting, and traveling!",
  },
  // {
  //   name: "David Lim",
  //   img: null,
  //   role: "Active Member",
  //   repeat: "2",
  //   email: "david.lim@berkeley.edu",
  //   year: "Sophomore",
  //   committee: "Media",
  //   project: "Sea Warden",
  //   tree: "Satiya Kem",
  //   leaf: "Rachel Xin",
  //   linkedin: "https://www.linkedin.com/in/davidlim7391/",
  //   resume: null,
  //   website:
  //     "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO",
  //   blurb: ":D",
  // },
  // {
  //   name: "Jada Klein",
  //   img: null,
  //   role: "Active Member",
  //   email: "jadaklein03@gmail.com",
  //   year: "Sophmore",
  //   committee: null,
  //   project: null,
  //   tree: null,
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/jada-klein/",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Heyo -- I'm a freshman studying CS. I like outdoor adventures, chill music, and yummy food :)",
  // },
  //{
  //    name: 'Lincoln Too',
  //    img: null,
  //    role: 'Active Member',
  //    email: 'lincoln99@berkeley.edu',
  //    year: 'Junior',
  //    committee: null,
  //    project: 'ClassifAI',
  //    tree: 'Ahmed',
  //    leaf: null,
  //    linkedin: null,
  //    resume: null,
  //    website: null,
  //    blurb: "I like anime, sports, and board games. HMU!",
  //},
  // {
  //   name: "Michael Zhang",
  //   img: null,
  //   role: "Active Member",
  //   year: "Senior",
  //   email: "zhang.michael@berkeley.edu",
  //   linkedin: null,
  //   tree: null,
  //   leaf: null,
  //   committee: null,
  //   project: null,
  //   resume: null,
  //   website: null,
  //   repeat: "2",
  //   blurb: "coffee, frank, n friends !!! say hi :)",
  // },
  // {
  //   name: "Mizuho Li",
  //   img: null,
  //   email: "limizuho@berkeley.edu",
  //   role: "Active Member",
  //   year: "Junior",
  //   committee: null,
  //   project: "Build a Bear",
  //   tree: "Ernest Goh",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/mizuho-li",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Hi I’m Mizuho! I love  playing any random sports and a BIG fan of k drama. ",
  // },
  // {
  //     name: 'Mallika Parulekar',
  //     img: null,
  //     role: 'Active Member',
  //     email: 'mallika.parulekar@berkeley.edu',
  //     year: 'Sophomore',
  //     committee: null,
  //     project: "Sea Warden",
  //     tree: "Aarushi",
  //     leaf: 'Ramya Chitturi',
  //     linkedin: "https://www.linkedin.com/in/mallikaparulekar/",
  //     resume: 'https://drive.google.com/file/d/1JF8Vr_nSIWZkEu12A21TLXBcvM4YatlS/view?usp=sharing',
  //     website: null,
  //     blurb: "test Hi! I'm Mallika - a sophomore studying computer science who's also interested in math, economics and the law. When I'm not drinking boba and playing tennis, you can find me building things, trying my hand at fullstack and being a human learning about machine learning :)",
  // },
  //
  // {
  //     name: 'Oliver Carmont',
  //     img: null,
  //     role: 'Active Member',
  //     email: 'ocarmont@berkeley.edu',
  //     year: 'Sophomore',
  //     committee: null,
  //     project: null,
  //     tree: null,
  //     leaf: null,
  //     linkedin: "https://www.linkedin.com/in/oliver-carmont/",
  //     resume: null,
  //     website: null,
  //     blurb: "Heyy! I'm a sophomore from Spain, who loves playing soccer, running and binging on cheez-its.",
  // },
  // {
  //     name: 'Shannon Or',
  //     img: null,
  //     role: 'Active Member',
  //     year: "Sophomore",
  //     email: "shannonor@berkeley.edu",
  //     committee: null,
  //     project: "beary hungry",
  //     tree: 'Kyle Hua',
  //     leaf: null,
  //     linkedin: null,
  //     resume: null,
  //     website: null,
  //     blurb: "Hi, I'm Shannon! I like playing basketball, snowboarding, and driving to 99ranch. Most importantly, if I could only eat one carb for the rest of my life it would be bread.",
  // },
  // {
  //     name: 'Tavleen Bhatia',
  //     img: null,
  //     role: 'Active Member',
  //     year: 'Sophomore',
  //     email: 'tavleenbhatia@berkeley.edu',
  //     linkedin: "https://www.linkedin.com/in/tavleen-bhatia-498491217/",
  //     tree: null,
  //     leaf: null,
  //     committee: null,
  //     project: null,
  //     resume: null,
  //     website: null,
  //     blurb: "Heyo! I'm a sophomore studying MCB and Data Science. I love working out, trying out new recipes, dreaming of owning a pet cat, and lying on the glade in the sun :)) Hmu to obsess over Grey's Anatomy or share Spotify playlists!",
  // },
  // {
  //   name: "Tiffanie Chau",
  //   img: null,
  //   role: "Active Member",
  //   email: "tiffaniechau@berkeley.edu",
  //   year: "Sophomore",
  //   committee: null,
  //   project: "Beary Hungry",
  //   tree: "ONLY THE BEST: AMY HUANG",
  //   leaf: "not ready to be a mother yet...",
  //   linkedin: "https://www.linkedin.com/in/tiffanielchau/",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Love to watch sunsets, yummy new foods, and good company(people in Codeo)!This will be my 5th semester in Codeology 💕",
  // },
  // {
  //     name: 'Tiffany Feng',
  //     img: null,
  //     email: "tiffanylinyifeng@berkeley.edu",
  //     role: 'Active Member',
  //     repeat: '2',
  //     year: 'Junior',
  //     committee: null,
  //     project: 'Beary-Hungry',
  //     tree: "Angela Jiang",
  //     leaf: null,
  //     linkedin: "https://www.linkedin.com/in/tiffanylinyifeng",
  //     resume: null,
  //     website: null,
  //     blurb: "Hi! I'm Tiffany, and I'm a Junior studying Computer Science and Business. I'm from San Diego, and I love being outdoors, baking, and traveling :)",
  // },

  // {
  //    name: 'Sadyant Behera',
  //    img: null,
  //    email: 'sadyant.behera@berkeley.edu',
  //    role: 'Advisor',
  //    year: "Senior",
  //    committee: null,
  //   project: 'sea warden',
  //    tree: 'shaylan dias',
  //    leaf: null,
  //    linkedin: 'https://linkedin.com/in/sadyant',
  //    resume: null,
  ///    website: 'https://GitHub.com/sadyant',
  //    blurb: 'i am huge (true)',
  //},

  {
    name: "Victoria Nguyen",
    img: null,
    role: "Active Member",
    year: "Freshman",
    email: "victoriahnguyen@berkeley.edu",
    committee: null,
    project: null,
    resume: null,
    tree: null,
    leaf: null,
    linkedin: "https://www.linkedin.com/in/victoriianguyen/",
    website: "https://victoriianguyen.github.io/",
    blurb: "lover of minecraft, milk, and mushrooms <33",
  },
  {
    name: "Riley Reichel",
    img: null,
    role: "Active Member",
    email: "",
    year: "Sophomore",
    committee: null,
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb:
      "I'm a sophomore studying EECS, interested in SWE and robotics. I like volleyball, reading, listening to music, and playing board games.",
  },
  // {
  //   name: "Chanbin Park",
  //   img: null,
  //   role: "Active Member",
  //   email: "chanbin.park@berkeley.edu",
  //   year: "Freshman",
  //   committee: null,
  //   project: null,
  //   tree: "Jun Hee Han",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/chanbin-park-4b795920b/",
  //   resume: null,
  //   website: "https://chanbinpark.com/",
  //   blurb:
  //     "I don't like avocados cuz they're too mushy. Can we change our mascot?",
  // },
  //{
  //    name: 'Eric Berndt',
  //    img: null,
  //        role: 'Active Member',
  //        email: 'ekberndt@berkeley.edu',
  //        year: 'Junior',
  //        committee: null,
  //        project: 'ClassifAI',
  //        tree: 'Ali Matsumoto',
  //        repeat: '2',
  //        leaf: null,
  //        linkedin: 'https://www.linkedin.com/in/ekberndt/',
  //        resume: null,
  //        website: null,
  //        blurb: "I love asking questions and learning new things :D",
  //    },
  //{
  //    name: 'Ernest Goh',
  //    img: null,
  //    role: 'Active Member',
  //    email: 'ernest.goh20@berkeley.edu',
  //    year: 'Sophomore',
  //    committee: null,
  //    project: null,
  //    tree: 'Jun Hee Han',
  //    leaf: 'Mizuho Li',
  //    linkedin: null,
  //    resume: null,
  //    website: null,
  //    blurb: null,
  //},
  //{
  //    name: 'Kevin Chow',
  //    img: null,
  //    role: 'Active Member',
  //    email: 'chowmein113@berkeley.edu',
  //    year: 'Junior',
  //    committee: null,
  //    project: 'Beary hungry',
  //    tree: 'Trevor Baba',
  //    leaf: 'I’m a leaf',
  //    repeat: '2',
  //    linkedin: 'https://www.linkedin.com/in/kevin-chow-engineer',
  //    resume: null,
  //    website: null,
  //    blurb: "You know that one friend that should obviously win a Darwin Award? That’s me. I’m that friend. I’m an EECS senior transfer from OC, CA. I love to hang out with Codeology fam and just have fun in general. HMU and I’d likely be down.",
  //},
  //{
  //    name: 'Kylie Ren',
  //    img: null,
  //    role: 'Active Member',
  //    email: 'kxyr@berkeley.edu',
  //    year: 'Junior',
  //    committee: null,
  //    project: null,
  //    tree: null,
  //    leaf: null,
  //    linkedin: null,
  //    resume: null,
  //    website: null,
  //    blurb: "Hi! I like going on photoshoots, playing valorant, studying plants/animals and making arts & crafts (or anything aesthetic lol)! ",
  //},
  // {
  //   name: "Lauren Zheng",
  //   img: null,
  //   role: "Active Member",
  //   email: "laurenz@berkeley.edu",
  //   year: "Sophomore",
  //   committee: null,
  //   project: "Build-a-bear",
  //   tree: "Daniel Tseng",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/lauren-zheng-2606",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "I love skiing, camping, Thai food, and playing the guitar! I'm from San Jose and my family just adopted a Corgi this summer  ♡ ♥ 🐶 ",
  // },
  //{
  //    name: 'Pradeep Muthaiya',
  //    img: null,
  //    role: 'Active Member',
  //    year: 'Junior',
  //    committee: null,
  //    project: "Shares^2",
  //    tree: "Krishna, Shaylan, Kyle, Karthick, Sree, Vaib, etc.",
  //    leaf: null,
  //    linkedin: null,
  //    resume: null,
  //    website: null,
  //    blurb: "CS and Stats Sophomore from SoCal just vibing. Hmu if ur in LA and wanna grab food :)",
  //},
  // {
  //   name: "Rachel Xin",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "rachelxin@berkeley.edu",
  //   project: null,
  //   tree: "David Lim",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/rachelxin",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Hello! I'm Rachel, and I am an EECS major from Orange County, CA. I enjoy playing tennis, baking/cooking, and trying new things during my free time.",
  // },
  //{
  //    name: 'Sebastiaan Szafir',
  //    img: null,
  //    role: 'Active Member',
  //    year: 'Sophomore',
  //    email: "sebastiaan@berkeley.edu",
  //    committee: null,
  //    project: 'ClassifAI',
  //    tree: "Ethan",
  //    leaf: "None yet",
  //    linkedin: 'https://www.linkedin.com/in/sebastiaan-szafir-fridman/',
  //    resume: null,
  //    website: "https://sebiszafir.com/",
  //    blurb: "I love the number 42, and I think you should too. I'm from Spain, love programming, board games, anime and have growing suspicions towards Berkeley's parking meters.",
  //},
  // {
  //   name: "Suhani Singhal",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "suhanisinghal04@berkeley.edu",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "hiii! I'm Suhani! I'm a third year studying EECS and Business. I love watching comedy shows and sci-fi films, trying new recipes, and listening to music with friends :) ",
  // },
  // {
  //   name: "David Qing",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "davidqing@berkeley.edu",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/davidqing/",
  //   resume: null,
  //   website: null,
  //   blurb: "hey! give me your playlists, i need new music :)",
  // },
  // {
  //   name: "Jack Kumashiro",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "jkwakecal25@berkeley.edu",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "www.linkedin.com/in/jack-kumashiro",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Hi! I'm Jack and I'm a second year EECS major from Arlington, VA. I love trying new foods, chilling with friends and really anything outside. I'm also on the Cal Triathlon team. Fun Fact, I'm a twin :)",
  // },
  {
    name: "Sarah Kiefer",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "sarahkiefer@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/sarah-kiefer-60965422a/",
    resume: null,
    website: null,
    blurb:
      "Heyy! I'm a sophomore studying Econ and Data Science. I love to cook, dance like nobody is watching at 3am, and just talk :) Fun fact: I grew up in the midwest so I know how to ride a horse but not a bike",
  },
  // {
  //   name: "Trevor Trinh",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "trevortrinh@berkeley.edu",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "https://linkedin.com/in/trevortrinh",
  //   resume: null,
  //   website: "https://trevz.me",
  //   blurb:
  //     "hey! I like learning about people and eating good food. Most of all, I like tommy :)",
  // },
  //{
  //    name: 'Joshua Aubin',
  //    img: null,
  //    role: 'Active Member',
  //    year: "",
  //    committee: null,
  //    email: "joshaubin@berkeley.edu",
  //    project: null,
  //    tree: '',
  //    leaf: null,
  //    linkedin: "https://www.linkedin.com/in/joshaubin/",
  //    resume: null,
  //    website: null,
  //    blurb: "Hi! I like bouldering, pc games, smash, and music. Hmu if you need a climbing buddy :)",
  //},
  {
    name: "Philip Ye",
    img: null,
    role: "Active Member",
    email: "",
    repeat: "2",
    year: "Sophomore",
    committee: null,
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb: "I've never puked on someone's phone. ",
  },
  {
    name: "Phillip Chen",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "phillipe10042003@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/phillipuchen/",
    resume: null,
    website: null,
    blurb: "An inter.",
  },
  // {
  //   name: "Razvan Turcu",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "razztech@berkeley.edu",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/razztech",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "👋I am Raz, a full-stack developer passionate about technology and entrepreneurship. In my free time, I practice 🥊kickboxing, play 🎾tennis, and cook 🍳food.",
  // },
  {
    name: "Aarushi Shah",
    img: null,
    role: "Active Member",
    email: "",
    year: "",
    committee: null,
    project: null,
    tree: "",
    leaf: "",
    repeat: "2",
    linkedin: "https://www.linkedin.com/in/aarush1shah",
    resume: "",
    website: "",
    blurb:
      "hi i’m aarushi!! i love playing nyt connections, dancing, and hiking. my hot take is that i don’t love the taste of avocados…",
  },
  {
    name: "Esme Can",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb:
      "Hii im Esme! I am double majoring in sociology and computer science! I love music, going to concerts, thrifting, and taking naps :))",
  },
  // {
  //   name: "Ashwin Parthasarathy",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/ashwin-parthas/",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Hey, I'm Ashwin! I love getting new music recommendations, taking road trips, going to concerts, spending the whole day in movie theaters, and making bad sports predictions.",
  // },
  {
    name: "Amber Gupta",
    img: null,
    role: "Active Member",
    year: "",
    committee: null,
    email: "",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "",
    resume: null,
    website: null,
    blurb:
      "hi I'm an intended data science major graduating in 2027! I like reading, embroidering, and the ocean. sharks are my favorite fish 𓆝 𓆟 𓆞",
  },
  // {
  //   name: "Katelyn Jo",
  //   img: null,
  //   role: "Active Member",
  //   year: "",
  //   committee: null,
  //   email: "",
  //   project: null,
  //   tree: "",
  //   leaf: null,
  //   linkedin: "https://www.linkedin.com/in/katelyn-jo/",
  //   resume: null,
  //   website: null,
  //   blurb:
  //     "Heyyy I'm Katelyn, and I'm studying Data Science and Cognitive Science. I am a SoCal girlie from Orange County where I love to go to amusement parks, do some window shopping at malls, and walk around the beach. Up here, I am learning ukulele, binging shows and movies, and going on drives around the Bay Area. My dream is to one day work in tech for the entertainment/media industry!",
  // },
  {
    name: "Kevin Hu",
    img: null,
    role: "Active Member",
    email: "",
    year: "",
    committee: null,
    project: null,
    tree: "",
    leaf: "",
    repeat: "4",
    linkedin: null,
    blurb:
      "Hello! My name is Kevin I am a Freshman intended in studying Data Science, I am a passionate film photographer and I love hiking!",
  },
  {
    name: "Ria Jain",
    img: null,
    role: "Active Member",
    email: "",
    year: "",
    committee: null,
    project: null,
    tree: "",
    leaf: "",
    linkedin: "https://www.linkedin.com/in/riajain230",
    blurb: "sunset lover and coffee enthusiast :)",
  },
  // {
  //   name: "AJ DeMarinis",
  //   img: null,
  //   role: "Active Member",
  //   email: "",
  //   year: "",
  //   committee: null,
  //   project: null,
  //   tree: "",
  //   leaf: "",
  //   linkedin: "https://www.linkedin.com/in/andrew-demarinis-96b76a241",
  //   blurb: "I'm a 61B TA so come to my OH",
  // },
  {
    name: "Ava Shah",
    img: null,
    role: "Active Member",
    email: "",
    year: "",
    committee: null,
    project: null,
    tree: "",
    leaf: "",
    linkedin: "https://www.linkedin.com/in/ava-shah-03709773",
    blurb: "I love IEOR and Moffitt",
  },
  {
    name: "Victor Zhou",
    img: null,
    role: "Active Member",
    email: "shuaivzhou@berkeley.edu",
    linkedin: null,
    blurb:
      "Reach out to me with any questions about pursuing software engineering!",
  },
  // TODO (yzdu): looks like we are also missing Andrei, lyyah, and Neil's profile?
];

function compare(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

const insertImg = (persons) => {
  for (let i = 0; i < persons.length; i++) {
    var obj = persons[i];
    const first =
      obj.name.split(" ")[0].toLowerCase() +
      (obj.hasOwnProperty("repeat") ? obj.repeat : "");
    try {
      obj.img = require("../images/members/" + first + ".jpg");
    } catch {
      obj.img = require("../images/members/default.jpg");
    }
  }
};

export const resourceContacts = [
  {
    name: "Joelle Siong Sin",
    img: null,
    role: "Senior, IEOR/CS",
    email: "jsiongsin@berkeley.edu",
    year: "Senior",
    committee: null,
    project: null,
    tree: "Sami!",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/joellesiongsin/",
    resume: null,
    website: null,
    blurb:
      "Hi! My name is Joelle, I’m a senior studying IEOR.  I’m interested in product management and the intersection of CS and Operations Research! Happy to chat more about changing majors, PM internships, or any similar interests!",
  },
  //{
  //    name: 'Pradyun Kumar',
  //    img: null,
  //    role: 'Junior, CS/Econ',
  //    email: 'pradyunkumar@berkeley.edu',
  //    year: 'Junior',
  //    committee: null,
  //    project: null,
  //    tree: null,
  //    leaf: null,
  //    linkedin: null,
  //    resume: null,
  //    website: null,
  //    blurb: "hi I like guacomole and anime and Kpop and listening to piano while I study",
  //},
  {
    name: "Victor Zhou",
    img: null,
    role: "Senior, CS",
    email: "shuaivzhou@berkeley.edu",
    linkedin: null,
    blurb:
      "Reach out to me with any questions about pursuing software engineering!",
  },
  {
    name: "Raghav Punnam",
    img: null,
    role: "Sophomore, EECS",
    committee: null,
    email: "raghavpu@berkeley.edu",
    project: null,
    tree: "",
    leaf: null,
    linkedin: "https://www.linkedin.com/in/raghav-punnam/",
    resume: null,
    website: null,
    blurb:
      "Hey, I'm Raghav! I have spent a lot of time self-learning about app/web full-stack development. I've worked on many projects within these fields. Feel free to reach out if you have any questions about these fields!",
  },
];

export const actives = a.slice(0, 1).concat(a.slice(1).sort(compare)); // sort everything after avocodeology!
insertImg(leadership);
insertImg(projectLeaders);
insertImg(a);
insertImg(resourceContacts);

// saving bios from people who haven't graduated/quit but aren't in member database :'(
/*
{
        name: 'Amy Huang',
        img:  null,
        role: 'Web Director',
        year: 'Junior',
        committee: 'Web!',
        email: 'amyxjhuang@berkeley.edu',
        project: null,
        tree: 'Anna',
        leaf: 'Tiffanie <3 :3',
        linkedin: 'https://www.linkedin.com/in/amyxjhuang/',
        resume: 'https://tinyurl.com/amyhuangresume',
        website: 'https://amyhuang.web.app',
        blurb: 'I love tasty pot, smoothie, hot cheeto puffs, animal crossing, moffitt 1st floor!!! ',
    },
    {
        name: 'Sami Oh',
        img:  null,
        role: 'Project Leader',
        email: 'haotiany@berkeley.edu',
        year: 'Junior',
        committee: null,
        project: 'Build-A-Bear',
        tree: "Grace Altree",
        leaf: null,
        linkedin: "https://www.linkedin.com/in/samantha-oh-3315b31b7/",
        resume: null,
        website: null,
        blurb: "I'm a junior interested in web design and development! I love cats, painting, and sitting in the sun :)",
    },
    {
        name: 'Kamille Dyan Fernando',
        img:  null,
        role: 'Project Leader',
        year: 'Junior',
        email: 'kamillef@berkeley.edu',
        committee: null,
        project: null,
        tree: 'Tiffany',
        leaf: null,
        linkedin: null,
        resume: null,
        website: null,
        blurb: null,
    },
    {
        name: 'Trevor Baba',
        img:  null,
        role: 'Project Leader',
        email:'trevor.baba@berkeley.edu',
        year: 'Junior',
        committee: null,
        project: "Pro-Bono",
        tree: 'Sherman',
        leaf: null,
        linkedin: 'https://www.linkedin.com/in/trevor-baba-000/',
        resume: null,
        website: null,
        blurb: 'I love playing basketball and collecting NFTs.',
    },
    {
        name: 'CJ Manna',
        img:  null,
        role: 'Active Member',
        year: 'Senior',
        email: 'charlesmanna@berkeley.edu',
        committee: null,
        project: null,
        tree: 'Micah',
        leaf: null,
        linkedin: null,
        resume: null,
        website: null,
        blurb: "Talk to me about hiking, movies, and ethical design!",
    },
    {
        name: 'Dustin Luong',
        img:  null,
        role: 'Active Member',
        email: 'dstn.luong@berkeley.edu',
        year: 'Senior',
        committee: null,
        project: null,
        tree: null,
        leaf: null,
        linkedin: null,
        resume: null,
        website: null,
        blurb: null,
    },
    {
        name: 'Satiya Kem',
        img:  null,
        role: 'Active Member',
        year: "Senior",
        committee: null,
        project: null,
        tree: null,
        leaf: null,
        linkedin: null,
        resume: null,
        website: null,
        blurb: null,
    },
*/
