const emails = [
    'danieltseng1529@berkeley.edu',
    'jasperchen3@berkeley.edu',
    'allengu01@berkeley.edu',
    'sebastiaan@berkeley.edu',
    'ajjiang@berkeley.edu',
    'cindyxzhang@berkeley.edu',
    'jsiongsin@berkeley.edu',
    'junheehan@berkeley.edu',
    'lari.tsai@berkeley.edu',
    'mayaz@berkeley.edu',
    'ashleychang@berkeley.edu',
    'ertsai01@berkeley.edu',
    'ashleyha@berkeley.edu',
    'cynthialan@berkeley.edu',
    'ethanyxchen@berkeley.edu',
    'stephen.yang@berkeley.edu',
    'euhan123@berkeley.edu',
    'mallika.parulekar@berkeley.edu',
    'chanbin.park@berkeley.edu',
    'rachelxin@berkeley.edu',
    'abaq@berkeley.edu',
    'ernest.goh20@berkeley.edu',
    'pradeep.muthaiya@berkeley.edu',
    'sadyant.behera@berkeley.edu',
    'ekberndt@berkeley.edu',
    'chowmein113@berkeley.edu',
    'shaylandias@berkeley.edu',
    'david.lim@berkeley.edu',
    'lincoln99@berkeley.edu',
    'limizuho@berkeley.edu',
    'ramya.chitturi@berkeley.edu',
    'shannonor@berkeley.edu',
    'chetan.khanna@berkeley.edu',
    'amoghm2@berkeley.edu',
    'danicat@berkeley.edu',
    'kevintnguyen@berkeley.edu',
    'kristielin13@berkeley.edu',
    'laurenz@berkeley.edu',
    'adahu@berkeley.edu',
    'alanliu2345@berkeley.edu',
    'ashchu@berkeley.edu',
    'gracealtree@berkeley.edu',
    'jasmineang@berkeley.edu',
    'krishnaravi@berkeley.edu',
    'tiffaniechau@berkeley.edu',
    'tiffanylinyifeng@berkeley.edu',
    'vaibg@berkeley.edu',
    'varun_murthy@berkeley.edu',
    'sreevidyaganga0@berkeley.edu',
    'dganesh@berkeley.edu',
    'shuaivzhou@berkeley.edu',
    'charlesming@berkeley.edu',
    'kerrine@berkeley.edu',
    'laur@berkeley.edu',
    'justinxu@berkeley.edu',
    'zhang.michael@berkeley.edu',
    'tavleenbhatia@berkeley.edu',
    'ocarmont@berkeley.edu',
    'kxyr@berkeley.edu',
    'tommyhang@berkeley.edu',
    'pradyunkumar@berkeley.edu',
    'angelmau@berkeley.edu',
    'grace.wj@berkeley.edu',
    'annie.xu2025@berkeley.edu',
    'jadaklein@berkeley.edu',
    'victoriahnguyen@berkeley.edu',
    'andrew.wang334@berkeley.edu',
    'amyxjhuang@berkeley.edu',
    'kylehuuuaaa@berkeley.edu'
];

export default emails;
